.geral-container-part2{
    display: flex;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(#03656E 100%,#146065 100%);
    align-items: center;
    width: 100vw;
    padding-bottom: 10%;
}

.video-container-parte2 {
    height: 400px;
    background-color: white;
    width: 700px;
    margin-top: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.texto-video-container {
    /* margin: 40px; */
    font-family: 'Nexa-Bold';
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
}

.text-container {
    color: white;
    margin: 0px;
    font-family: EuphemiaUCAS;
    text-align: center;
    font-weight: 400;
}

.top-text-part2{
    line-height: 3rem;
    color: white;
    font-size: 3.6rem;
    font-family: EuphemiaUCAS;
}

.top-text2-part2 {
    font-family: EuphemiaUCAS;
    font-weight: bold;
    font-size: 3.6rem;
    color: white;
}

.video {
    border-radius: 10px;
}


@media screen and (max-height: 1000px) {
    .geral-container-part2{
        height: 100%;
    }
}

@media screen and (max-height: 500px) {
    .geral-container-part2 {
        height: 100%;
    }
}

@media (max-width: 1280px){
    .geral-container-part2{
        height: 100%;
        padding-top: 20px;
        padding-bottom: 20%;
    }
    .video-container-parte2 {
        width: 90vw;
    }
}

@media screen and (max-width: 768px) {
    .video {
        width: 90vw;
    }
}

@media (max-width: 540px) {
    .geral-container-part2{
        height: 100%;
        padding-top: 20px;
    }

    .top-text-part2 {
        line-height: 4.2rem;
    }
    .video-container-parte2 {
        margin-bottom: 35px;
    }
    .video {
        width: 90vw;
        height: 220px;
    }
}

@media screen and (max-width: 300px) {
    .video {
        width: 90vw;
        height: 150px;
    }
}

@media screen and (max-width: 400px) and (max-height: 640px) {
    .geral-container-part2 {
        height: 100%;
    }
    .video-container-parte2 {
        height: 30vh;
        width: 90vw;

    }
}


@media screen and (max-width: 400px) and (max-height: 1000px) {
    .geral-container-part2 {
        padding-top: 20px;
        height: 80vh;
    }
}
