.right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: center;
  box-sizing: border-box;
}

.text-right-container p {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 3rem;
  line-height: 3.5rem;
}

.right-content {
  padding: 0 40px 0 40px;
}

.x-button {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  color: black;
}

.x-icon {
  color: #03656e;
  height: 5vh;
}

.divided-fields {
  display: flex;
}

.right-field-container {
  width: 48%;
  margin-left: 10px;
}

.left-field-container {
  width: 50%;
  margin-right: 10px;
}

#celular {
  color: #03656e;
}

#celular,
#email {
  width: 100%;
  margin-right: 10px;
}

#dataNascimento,
#cpf {
  width: 100%;
}

.btn-politica-privacidade {
  background: transparent;
  border: none;
  color: #0d6efd;
  text-decoration: underline;
}

.text-right-container {
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  padding-bottom: 30px;
  font-family: Nexa-Bold;
  font-weight: bold;
  width: 100%;
  align-items: center;
}

Form .form-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
}

Form .form-control input {
  margin-top: 15px;
}

.field::placeholder {
  padding-left: 2px;
}
.field {
  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 13px;
  font-size: 15px;
  padding-bottom: 2px;
}

#error {
  color: red;
  font-size: 10px;
  margin-top: 0px;
  padding-top: 0px;
}

.formulario input::placeholder {
  /* font-size: 2em; */
  color: black;
}

.formulario label:focus {
  outline: none;
}

.label-text-cnh {
  font-size: 1.8rem;
}

.select-container {
  padding-left: 7px;
}

/* input[type=date] {
    color: transparent !important;
} */

.condicoes-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  padding-left: 10px;
}

.condicoes-container label {
  padding-left: 20px;
  font-size: 1.5rem;
  line-height: 2rem;
}

.button-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.button-avancar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4ab8a8;
  width: 40%;
  align-self: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 42px;
  color: white;
  font-family: 'NexaRegular';
  font-size: 2rem;
  line-height: 1.6rem;
  height: 70px;
  min-height: 50px;
  min-width: max-content;
  margin-bottom: 20px;
}

.loading {
  background-color: lightgrey;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 2rem;
  height: 2rem;
  margin-left: 10px;
}

.select-form {
  padding-left: 10px;
  padding-top: 2px;
  font-size: 1.5rem;
  border-radius: 5px;
}

@media screen and (max-height: 720px) {

  .text-right-container p {
    height: 70%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 2rem;
    line-height: 3.5rem;
  }

  
}

@media screen and (max-width: 900px) {
  .right-container {
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 100%;
  }

  .text-right-container {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .formulario {
    width: 55%;
  }
}

@media screen and (max-width: 430px) {
  .divided-fields {
    flex-direction: column;
    display: flex;
  }

  .right-content {
    padding: 30px;
  }

  .text-right-container {
    font-size: 3rem;
    line-height: 3rem;
  }

  .right-field-container {
    margin-left: 0px;
    width: 100%;
  }

  .condicoes-container label {
    font-size: 2.2rem;
    line-height: 2.5rem;
  }

  .left-field-container {
    width: 100%;
    margin-right: 0px;
  }
}
